import React, { useEffect, useRef } from 'react';
import backgroundImage from './assets/img/color-sharp.png';
import VanillaTilt from 'vanilla-tilt';
import Footer from './Footer';
import ServiceNavbar from './ServicesNavBar';

const AIML = () => {
  const tiltRefs = useRef([]);

  useEffect(() => {
    tiltRefs.current.forEach((card) => {
      VanillaTilt.init(card, {
        max: 25,
        speed: 400,
        glare: true,
        'max-glare': 0.4,
      });

      return () => {
        if (card && card.vanillaTilt) {
          card.vanillaTilt.destroy();
        }
      };
    });
  }, []);

  const containerStyles = {
    display: 'grid',
    gap: '20px',
    gridTemplateColumns: 'repeat(auto-fit, minmax(300px, 1fr))',
    marginBottom: '40px', // Space at the bottom to avoid overlapping with footer
  };

  const cardStyles = {
    position: 'relative',
    width: '100%',
    height: '100%',
    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
    borderRadius: '15px',
    background: 'rgba(255, 255, 255, 0.1)', // Adjust the background color and opacity as needed
    overflow: 'hidden',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    transition: 'transform 0.3s ease',
    cursor: 'pointer',
    padding: '20px',
  };

  const contentStyles = {
    textAlign: 'center',
  };

  const headingStyles = {
    fontSize: '1.8em',
    color: 'aliceblue',
    marginBottom: '15px',
  };

  const paragraphStyles = {
    fontSize: '1em',
    color: '#fff',
    fontWeight: '300',
  };

  return (
    <div
      className="aiml-container"
      style={{
        backgroundImage: `url(${backgroundImage})`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        paddingTop: '60px',
      }}
    >
      <ServiceNavbar />
      <div className="container" style={{ paddingTop: '100px', paddingBottom: '40px' }}> {/* Padding added to prevent overlap with navbar */}
        <div className="row">
          <div className="col-md-12">
            <div className="heading" style={{ textAlign: 'center', marginTop: '60px' }}>
              <h1 style={{ color: 'white' }}>Artificial Intelligence & Machine Learning</h1>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-md-10 offset-md-1">
            <div className="cards-container" style={containerStyles}>
              {[
                'Natural Language Processing (NLP)',
                'Computer Vision',
                'Predictive Analytics',
                'Deep Learning',
                'Reinforcement Learning',
                'AI Ethics',
              ].map((title, index) => (
                <div
                  key={index}
                  ref={(el) => {
                    if (el) {
                      tiltRefs.current.push(el);
                    }
                  }}
                  className="card"
                  style={cardStyles}
                >
                  <div className="content" style={contentStyles}>
                    <h3 style={headingStyles}>{title}</h3>
                    <p style={paragraphStyles}>
                      {title === 'Natural Language Processing (NLP)' && 'Understanding and processing human language for tasks like text classification and sentiment analysis.'}
                      {title === 'Computer Vision' && 'Enabling machines to interpret and analyze visual information from the real world.'}
                      {title === 'Predictive Analytics' && 'Using data, statistical algorithms, and machine learning techniques to identify the likelihood of future outcomes.'}
                      {title === 'Deep Learning' && 'Training neural networks with large datasets to learn and make decisions on their own.'}
                      {title === 'Reinforcement Learning' && 'Teaching machines to make sequences of decisions through trial and error, guided by feedback.'}
                      {title === 'AI Ethics' && 'Addressing ethical concerns and implications of AI technologies in society.'}
                    </p>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default AIML;
