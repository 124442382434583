import React from 'react';
import { Helmet } from 'react-helmet';
import navIcon1 from './assets/img/nav-icon1.svg';
import navIcon2 from './assets/img/x.png';
import navIcon3 from './assets/img/nav-icon3.svg';

const SocialIcons = () => {
  return (
    <>
      <Helmet>
        <title>YTC TechVentures</title>
        <meta name="YTC TechVentures" content="YTC TechVentures - Social Media Presence" />
        <meta name="YTC TechVentures" content="YTC TechVentures, social media, LinkedIn, Twitter, Instagram" />
        <meta property="og:YTC TechVentures" content="YTC TechVentures" />
        <meta property="og:description" content="YTC TechVentures - Social Media Presence" />
        <meta property="og:type" content="website" />
        <meta property="og:image" content={navIcon1} />
        <meta property="og:url" content="https://example.com/ytc-techventures" />
        <meta name="twitter:YTC TechVentures" content="summary_large_image" />
        <meta name="twitter:YTC TechVentures" content="YTC TechVentures" />
        <meta name="twitter:YTC TechVentures" content="YTC TechVentures - Social Media Presence" />
        <meta name="twitter:image" content={navIcon1} />
      </Helmet>
      <div className="social-icons">
        <div className="social-icon">
          <a href="https://www.linkedin.com/in/ytc-techventures-0b44a3312/?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=ios_app" target="_blank" rel="noopener noreferrer">
            <img src={navIcon1} alt="Linkdin" />
          </a>
        </div>
        <div className="social-icon">
          <a href="https://x.com/YTCTechVentures?t=7fKCXEibZOUVH-ElaTuj3A&s=08" target="_blank" rel="noopener noreferrer">
            <img src={navIcon2} alt="Twitter" />
          </a>
        </div>
        <div className="social-icon">
          <a href="https://www.instagram.com/ytc.techventures?igsh=MWZiMW96b3NtM2NhNA==" target="_blank" rel="noopener noreferrer">
            <img src={navIcon3} alt="Instagram" />
          </a>
        </div>
      </div>
    </>
  );
}

export default SocialIcons;
