import React, { useState } from 'react';
import { Button } from 'react-bootstrap';
import './style/ConnectForm.css';
import { Helmet } from 'react-helmet';
import axios from 'axios';

const ConnectForm = ({ isVisible, onClose }) => {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    contact: '',
    reason: 'web-development',
    message: ''
  });

  const [errors, setErrors] = useState({
    name: '',
    contact: ''
  });

  const validateName = (name) => {
    const nameRegex = /^[a-zA-Z\s]*$/;
    if (!nameRegex.test(name)) {
      return 'Please Check Name.';
    }
    return '';
  };

  const validateContactNumber = (number) => {
    const numberRegex = /^[0-9]*$/;
    if (!numberRegex.test(number)) {
      return 'Please check Contact number.';
    }
    return '';
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });

    if (name === 'name') {
      const errorMessage = validateName(value);
      setErrors((prevErrors) => ({ ...prevErrors, name: errorMessage }));
    }

    if (name === 'contact') {
      const errorMessage = validateContactNumber(value);
      setErrors((prevErrors) => ({ ...prevErrors, contact: errorMessage }));
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const nameError = validateName(formData.name);
    const contactError = validateContactNumber(formData.contact);

    if (nameError || contactError) {
      setErrors({ name: nameError, contact: contactError });
      return;
    }

    try {
      console.log('Submitting form:', formData);
      const response = await axios.post(
        'https://backend.yadavtechnicalcorp.com/admin/submitForm',
        formData,
        {
          headers: {
            'Content-Type': 'application/json',
            'access-key': "ASDFGHJ23456$^%$*&dhsjdhshLMKNGBHGsjdgash23bsh"
          },
        }
      );
      console.log('Response:', response);
      if (response.status === 200) {
        alert('Form submitted successfully!');
        setFormData({
          name: '',
          email: '',
          contact: '',
          reason: 'web-development',
          message: ''
        });
        setErrors({
          name: '',
          contact: ''
        });
      } else {
        alert('Failed to submit form. Please try again later.');
      }
    } catch (error) {
      console.error('Error submitting form:', error);
      alert('Failed to submit form. Please try again later.');
    }
  };

  const handleClose = () => {
    setFormData({
      name: '',
      email: '',
      contact: '',
      reason: 'web-development',
      message: ''
    });
    setErrors({
      name: '',
      contact: ''
    });
    onClose();
  };

  return (
    <>
      <Helmet>
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <title>Connect with Us - YTC TechVenture</title>
        <meta name="description" content="Get in touch with us at YTC TechVenture for support, feedback, or general inquiries. Fill out the form to connect with our team." />
        <meta name="keywords" content="Connect, Contact Us, Support, Feedback, General Inquiry, YTC TechVenture" />
        <meta name="author" content="YTC TechVenture" />
        <meta property="og:title" content="Connect with Us - YTC TechVenture" />
        <meta property="og:description" content="Get in touch with us at YTC TechVenture for support, feedback, or general inquiries. Fill out the form to connect with our team." />
      </Helmet>
      <div className={`connect-form ${isVisible ? 'visible' : ''}`}>
        <Button variant="light" className="close-button" onClick={handleClose}>X</Button>
        <div className="form-content">
          <h2 className="tagline text-center">Connect with Us</h2>
          <form onSubmit={handleSubmit}>
            <div className="mb-3">
              <label className="form-label" htmlFor="name">
                Name <span className="required">*</span>
              </label>
              <input
                type="text"
                className={`form-control ${errors.name ? 'is-invalid' : ''}`}
                id="name"
                name="name"
                value={formData.name}
                onChange={handleInputChange}
                required
              />
              {errors.name && <div className="invalid-feedback">{errors.name}</div>}
            </div>
            <div className="mb-3">
              <label className="form-label" htmlFor="email">
                Email <span className="required">*</span>
              </label>
              <input
                type="email"
                className="form-control"
                id="email"
                name="email"
                value={formData.email}
                onChange={handleInputChange}
                required
              />
            </div>
            <div className="mb-3">
              <label className="form-label" htmlFor="contact">
                Phone Number <span className="required">*</span>
              </label>
              <input
                type="tel"
                className={`form-control ${errors.contact ? 'is-invalid' : ''}`}
                id="contact"
                name="contact"
                value={formData.contact}
                onChange={handleInputChange}
                required
              />
              {errors.contact && <div className="invalid-feedback">{errors.contact}</div>}
            </div>
            <div className="mb-3">
              <label className="form-label" htmlFor="reason">
                Reason to Connect <span className="required">*</span>
              </label>
              <select
                className="form-select"
                id="reason"
                name="reason"
                value={formData.reason}
                onChange={handleInputChange}
                required
              >
                <option value="web-development">Web Development</option>
                <option value="application-development">Application Development</option>
                <option value="digital-marketing">Digital Marketing</option>
                <option value="seo-services">SEO Services</option>
                <option value="ai-ml-services">AI/ML Services</option>
                <option value="cloud-services">Cloud Services</option>
                <option value="other">Other</option>
              </select>
            </div>
            <div className="mb-3">
              <label className="form-label" htmlFor="message">
                Message <span className="required">*</span>
              </label>
              <textarea
                className="form-control"
                id="message"
                name="message"
                value={formData.message}
                onChange={handleInputChange}
                required
              ></textarea>
            </div>
            <Button type="submit" variant="primary" className="tagline">
              Submit
            </Button>
          </form>
        </div>
      </div>
    </>
  );
};

export default ConnectForm;
