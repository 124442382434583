import React, { useState, useEffect } from "react";
import { Navbar, Container } from "react-bootstrap";
import { Helmet } from "react-helmet";
import logo from './assets/img/YTC.png';

const ServiceNavbar = () => {
  const [scrolled, setScrolled] = useState(false);
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      const offset = window.scrollY;
      setScrolled(offset > 50);
    };

    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768); 
    };

    window.addEventListener("scroll", handleScroll);
    window.addEventListener("resize", handleResize);

    // Initial check for mobile
    handleResize();

    return () => {
      window.removeEventListener("scroll", handleScroll);
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const handleHomeClick = () => {
    window.history.back();
  };

  return (
    <>
      <Helmet>
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <title>YTC TechVenture</title>
        <meta name="description" content="To empower businesses with innovative, reliable, and scalable technology solutions that drive growth and efficiency. We are committed to delivering exceptional value and fostering long-term partnerships through excellence in service and continuous innovation." />
        <meta name="keywords" content="Web Development, Application Development, SEO Services, Cloud Services, On-Demand Services" />
        <meta name="author" content="YTC TechVenture" />
        <meta property="og:title" content="YTC TechVenture - Innovate. Integrate. Inspire." />
        <meta property="og:description" content="Innovate. Integrate. Inspire." />
      </Helmet>
      <Navbar
        expand="md"
        style={{
          backgroundColor: scrolled ? '#121212' : 'transparent',
          transition: 'background-color 0.3s',
        }}
        className="justify-content-between" // To align brand/logo and home button to opposite ends
      >
        <Container>
          <Navbar.Brand href="/">
            <img src={logo} alt="Logo" className="navbar-logo" />
          </Navbar.Brand>
          {isMobile ? (
            <button
              className="vvd ml-auto"
              onClick={handleHomeClick}
              style={{
                color: '#fff',
                border: '1px solid #fff',
                borderRadius: '5px',
                padding: '8px 15px',
                background: 'transparent',
                cursor: 'pointer',
                transition: 'color 0.3s', // Add transition for smooth color change
              }}
              onMouseEnter={(e) => e.target.style.color = 'black'} // Change color on hover
              onMouseLeave={(e) => e.target.style.color = '#fff'} // Revert color on mouse leave
            >
              <span>Home</span>
            </button>
          ) : (
            <Navbar.Toggle aria-controls="basic-navbar-nav" />
          )}
          <Navbar.Collapse id="basic-navbar-nav">
            <span className="navbar-text ml-auto">
              <button
                className="vvd"
                onClick={handleHomeClick}
                style={{
                  color: '#fff',
                  transition: 'color 0.3s', 
                }}
                onMouseEnter={(e) => e.target.style.color = 'black'} 
                onMouseLeave={(e) => e.target.style.color = '#fff'} 
              >
                <span>Home</span>
              </button>
            </span>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </>
  );
};

export default ServiceNavbar;
