import React, { useEffect, useState } from 'react';
import { Table, Container, Row, Col, Card, Button } from 'react-bootstrap';
import axios from 'axios';
import * as XLSX from 'xlsx';
import AppNavbar from './AdminNav';
import useAuth from './Auth'; 

const UserContactDetails = () => {
  const isAuth = useAuth(); 
  const [leads, setLeads] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    if (isAuth) {
      const fetchLeads = async () => {
        try {
          const response = await axios.get('https://backend.yadavtechnicalcorp.com/admin/getLeads', {
            headers: {
              'Content-Type': 'application/json',
              'access-key': 'ASDFGHJ23456$^%$*&dhsjdhshLMKNGBHGsjdgash23bsh'
            }
          });

          // console.log('Fetched leads:', response.data.requestData);
          setLeads(response.data.requestData);
          setLoading(false);
        } catch (error) {
          // console.error('Error fetching leads:', error);
          setError(error.response ? error.response.data.message : error.message);
          setLoading(false);
        }
      };

      fetchLeads();
    }
  }, [isAuth]);

  const exportToExcel = () => {
    const fileName = 'LeadDetails.xlsx';
    const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';

    const formattedData = leads.map((lead, index) => ({
      Sno: index + 1,
      Name: lead.name,
      Email: lead.email,
      'Contact Number': lead.contact,
      Reason: lead.reason,
      Message: lead.message,
    }));

    const ws = XLSX.utils.json_to_sheet(formattedData);
    const wb = { Sheets: { 'data': ws }, SheetNames: ['data'] };
    const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
    const data = new Blob([excelBuffer], { type: fileType });
    const url = URL.createObjectURL(data);

    const a = document.createElement('a');
    a.href = url;
    a.download = fileName;
    a.click();
    URL.revokeObjectURL(url);
  };

  if (!isAuth) {
    return null; 
  }

  return (
    <div className="user-contact-details">
      <AppNavbar />
      <Container className="mt-5 pt-5 pb-5">
        <Row className="justify-content-center">
          <Col xs={12} md={10}>
            <Card>
              <Card.Body>
                <Card.Title className="text-center mb-4">Lead Details</Card.Title>
                <div className="table-responsive">
                  <Table striped bordered hover responsive>
                    <thead className="thead-dark">
                      <tr>
                        <th>Sno</th>
                        <th>Name</th>
                        <th>Email</th>
                        <th>Contact Number</th>
                        <th>Reason</th>
                        <th>Message</th>
                      </tr>
                    </thead>
                    <tbody>
                      {loading ? (
                        <tr>
                          <td colSpan="6" className="text-center">Loading...</td>
                        </tr>
                      ) : error ? (
                        <tr>
                          <td colSpan="6" className="text-center text-danger">Error: {error}</td>
                        </tr>
                      ) : leads.length > 0 ? (
                        leads.map((lead, index) => (
                          <tr key={index}>
                            <td>{index + 1}</td>
                            <td>{lead.name}</td>
                            <td>{lead.email}</td>
                            <td>{lead.contact}</td>
                            <td>{lead.reason}</td>
                            <td>{lead.message}</td>
                          </tr>
                        ))
                      ) : (
                        <tr>
                          <td colSpan="6" className="text-center">No leads available</td>
                        </tr>
                      )}
                    </tbody>
                  </Table>
                </div>
                <div className="text-center mt-3">
                  <Button variant="success" onClick={exportToExcel}>Export to Excel</Button>
                </div>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default UserContactDetails;
