import React, { useState } from 'react';
import { Container, Row, Col } from "react-bootstrap";
import ServicesCard from "./ServicesCard";
import webDevlopment from "./assets/img/web-developments.jpg";
import ApplicationDevlopment from "./assets/img/application.png";
import DigitalMarketing from "./assets/img/digital-marketing.jpg";
import Seo from "./assets/img/seo.jpg";
import cloud from "./assets/img/cloud.png";
import AI from "./assets/img/ai.png";
import colorSharp2 from "./assets/img/color-sharp2.png";
import TrackVisibility from 'react-on-screen';
import { Helmet } from 'react-helmet';
import TabletCard from './TabletCard'; 
import ConnectForm from './ConnectForm'; 
import { ArrowRightCircle } from 'react-bootstrap-icons';
import './style/Services.css';

const Services = ({ onConnectClick }) => { 
  const [formVisible, setFormVisible] = useState(false); 

  const services = [
    {
      title: "Application Development",
      imgUrl: ApplicationDevlopment,
    },
    {
      title: "Web Development",
      imgUrl: webDevlopment,
    },
    {
      title: "Digital Marketing",
      imgUrl: DigitalMarketing,
    },
    {
      title: "SEO Services",
      imgUrl: Seo,
    },
    {
      title: "Cloud Services",
      imgUrl: cloud,
    },
    {
      title: "AI-ML Services",
      imgUrl: AI,
    },
  ];

  const projects = [
    {
      title: 'Photowala',
      link: 'https://photowala.vercel.app/',
      description: [
        'Photowala is a comprehensive platform for photographers to showcase their portfolios and manage their work efficiently. It offers a visually appealing and user-friendly experience.',
        'Key Features:',
        'Stunning portfolio section with high-quality image previews, categorized by events and themes.',
        'Customizable layouts to match the photographer\'s style.',
        'Powerful admin panel for content management, including photo uploads and gallery organization.',
        'Secure client login with OTP for viewing private galleries.',
        'Clients can download images, share on social media, or order prints.',
        'Fully responsive design for optimal viewing on desktops, tablets, and smartphones.',
        'SEO Optimization for better search engine visibility.',
        'Analytics Integration to track visitor behavior and engagement.',
        'Partner with us and experience our expertise in delivering innovative technology solutions.'
      ],
      reverseLayout: false
    },
    {
      title: 'Kids Store ',
      link: 'https://kidicartwebsite.vercel.app/',
      description: [
        'Kids Store offers a comprehensive online platform designed to drive business growth and enhance customer satisfaction. With secure user authentication, customers can easily sign up, log in, and manage their profiles.',
        'Key Features:',
        'Categorized product listings for toys, clothes, and baby materials with detailed descriptions, high-quality images, prices, and user reviews.',
        'Integrated shopping cart, order summary, and payment gateway for a smooth and efficient checkout process.',
        'Real-time order tracking, order history, and notifications via push alerts and emails for order updates and special offers.',
        'Wishlist feature to save favorite items for future purchases.',
        'User engagement options to review and rate products, enhancing trust and community.',
        'Comprehensive account management for personal details, address books, and saved payment methods.',
        'The benefits of Kids Store\'s online presence include increased reach and accessibility, enhanced customer engagement, streamlined operations, data-driven insights, a competitive edge, scalability, and improved customer convenience.'
      ],
      reverseLayout: true
    }
  ];

  const handleConnectClick = () => {
    setFormVisible(true);
  };

  const handleCloseForm = () => {
    setFormVisible(false);
  };

  const renderDescription = (description) => {
    return (
      <>
        <p className="project-text">{description[0]}</p>
        <ul className="project-list">
          {description.slice(1).map((line, index) => (
            <li key={index}>{line}</li>
          ))}
        </ul>
      </>
    );
  };

  return (
    <>
      <Helmet>
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <title>Our Services - YTC TechVenture</title>
        <meta name="description" content="Discover the wide range of services we offer at YTC TechVenture, including application development, web development, digital marketing, SEO services, additional IT services, and on-demand services." />
        <meta name="keywords" content="Application Development, Web Development, Digital Marketing, SEO Services, IT Services, On-Demand Services" />
        <meta name="author" content="YTC TechVenture" />
        <meta property="og:title" content="Our Services - YTC TechVenture" />
        <meta property="og:description" content="Discover the wide range of services we offer at YTC TechVenture, including application development, web development, digital marketing, SEO services, additional IT services, and on-demand services." />
      </Helmet>
      <section className="service" id="services">
        <Container>
          <Row>
            <Col>
              <TrackVisibility>
                {({ isVisible }) =>
                  <div className={isVisible ? "animate__animated animate__fadeIn text-center" : "text-center"}>
                    <h2 style={{color:'white'}}>Our Services</h2>
                    <div className="mb-4"></div>
                    <Row>
                      {services.map((service, index) => (
                        <ServicesCard
                          key={index}
                          {...service}
                        />
                      ))}
                    </Row>
                  </div>
                }
              </TrackVisibility>
            </Col>
          </Row>
        </Container>
        <img className="background-image-right" src={colorSharp2} alt="Background" />
      </section>
      <div className="project-cards-container">
        <Container>
          <h2 className="project-title">Our Work</h2>
          {projects.map((project, index) => (
            <Row key={index}>
              {project.reverseLayout ? (
                <>
                  <Col xs={12} md={6} className="project-description">
                    <div className="project-info">
                      <h3 className="project-subtitle">{project.title}</h3>
                      {renderDescription(project.description)}
                      {/* <button 
                        className="connect-button"
                        onClick={handleConnectClick}
                      >
                        Let’s Connect <ArrowRightCircle size={35} />
                      </button> */}
                    </div>
                  </Col>
                  <Col xs={12} md={6} className="project-card">
                    <TabletCard project={project} />
                  </Col>
                </>
              ) : (
                <>
                  {/* <Col xs={12} md={6} className="project-card">
                    <TabletCard project={project} />
                  </Col>
                  <Col xs={12} md={6} className="project-description">
                    <div className="project-info">
                      <h3 className="project-subtitle">{project.title}</h3>
                      {renderDescription(project.description)}
                      <button 
                        className="connect-button"
                        onClick={handleConnectClick}
                      >
                        Let’s Connect <ArrowRightCircle size={35} />
                      </button>
                    </div>
                  </Col> */}
                </>
              )}
            </Row>
          ))}
        </Container>
      </div>
      <ConnectForm visible={formVisible} onClose={handleCloseForm} />
    </>
  );
};

export default Services;
