import React from 'react';
import PropTypes from 'prop-types';
import './style/ProjectCard.css';

const ProjectCard = ({ project }) => {
  const cardStyles = {
    position: 'relative',
    width: '100%',
    maxWidth: '300px',
    height: '500px',
    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
    borderRadius: '20px',
    backgroundColor: '#fff',
    overflow: 'hidden',
    margin: '20px 0',
  };

  const iframeStyles = {
    width: '100%',
    height: '100%',
    border: 'none',
  };

  return (
    <div className="project-card" style={cardStyles}>
      <iframe src={project.link} title={project.title} style={iframeStyles}></iframe>
    </div>
  );
};

ProjectCard.propTypes = {
  project: PropTypes.shape({
    link: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
  }).isRequired,
};

export default ProjectCard;
