import React, { useState, useEffect, useRef } from 'react';
import backgroundImage from './assets/img/color-sharp.png';
import VanillaTilt from 'vanilla-tilt';
import Footer from './Footer';
import ServiceNavbar from './ServicesNavBar';
import TabletCard from './TabletCard'; 
import ConnectForm from './ConnectForm'; 
import './style/WebDevelopment.css';


const WebDevelopment = () => {
  const [loading, setLoading] = useState(true);
  const tiltRefs = useRef([]);
  const [formVisible, setFormVisible] = useState(false); 

  useEffect(() => {
    const timer = setTimeout(() => {
      setLoading(false);
    }, 100);

    return () => clearTimeout(timer);
  }, []);

  useEffect(() => {
    if (!loading) {
      tiltRefs.current.forEach((card) => {
        VanillaTilt.init(card, {
          max: 25,
          speed: 400,
          glare: true,
          'max-glare': 0.4,
        });

        return () => {
          if (card && card.vanillaTilt) {
            card.vanillaTilt.destroy();
          }
        };
      });
    }
  }, [loading]);

  const containerStyles = {
    display: 'grid',
    gap: '20px',
    gridTemplateColumns: 'repeat(auto-fit, minmax(300px, 1fr))',
    marginBottom: '40px', 
  };

  const cardStyles = {
    position: 'relative',
    width: '100%',
    height: '100%',
    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
    borderRadius: '15px',
    background: 'rgba(128, 128, 128, 0.5)',
    overflow: 'hidden',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    transition: 'transform 0.3s ease',
    cursor: 'pointer',
  };

  const contentStyles = {
    padding: '20px',
    textAlign: 'center',
  };

  const headingStyles = {
    fontSize: '1.8em',
    color: 'aliceblue',
    marginBottom: '15px',
  };

  const paragraphStyles = {
    fontSize: '1em',
    color: '#fff',
    fontWeight: '300',
  };

  const projects = [
    {
      title: 'Photowala',
      link: 'https://photowala.vercel.app/',
      description: [
        'Photowala is a comprehensive platform for photographers to showcase their portfolios and manage their work efficiently. It offers a visually appealing and user-friendly experience.',
        'Key Features:',
        'Stunning portfolio section with high-quality image previews, categorized by events and themes.',
        'Customizable layouts to match the photographer\'s style.',
        'Powerful admin panel for content management, including photo uploads and gallery organization.',
        'Secure client login with OTP for viewing private galleries.',
        'Clients can download images, share on social media, or order prints.',
        'Fully responsive design for optimal viewing on desktops, tablets, and smartphones.',
        'SEO Optimization for better search engine visibility.',
        'Analytics Integration to track visitor behavior and engagement.',
        'Partner with us and experience our expertise in delivering innovative technology solutions.'
      ],
      reverseLayout: false
    },
    {
      title: 'Kids Store ',
      link: 'https://kidicartwebsite.vercel.app/',
      description: [
        'Kids Store offers a comprehensive online platform designed to drive business growth and enhance customer satisfaction. With secure user authentication, customers can easily sign up, log in, and manage their profiles.',
        'Key Features:',
        'Categorized product listings for toys, clothes, and baby materials with detailed descriptions, high-quality images, prices, and user reviews.',
        'Integrated shopping cart, order summary, and payment gateway for a smooth and efficient checkout process.',
        'Real-time order tracking, order history, and notifications via push alerts and emails for order updates and special offers.',
        'Wishlist feature to save favorite items for future purchases.',
        'User engagement options to review and rate products, enhancing trust and community.',
        'Comprehensive account management for personal details, address books, and saved payment methods.',
        'The benefits of Kids Store\'s online presence include increased reach and accessibility, enhanced customer engagement, streamlined operations, data-driven insights, a competitive edge, scalability, and improved customer convenience.'
      ],
      reverseLayout: true
    }
  ];

  const handleConnectClick = () => {
    setFormVisible(true);
  };

  const handleCloseForm = () => {
    setFormVisible(false);
  };

  return (
    <div
      className="web-development-container"
      style={{
        backgroundImage: `url(${backgroundImage})`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        paddingTop: '60px',
      }}
    >
      <ServiceNavbar />
      {!loading && (
        <div className="web-development">
          <div className="container" style={{ paddingTop: '100px', paddingBottom: '40px' }}>
            <div className="row">
              <div className="col-md-12">
                <div className="heading" style={{ textAlign: 'center', marginTop: '60px' }}>
                  <h1 style={{ color: 'white' }}>Web Development</h1>
                </div>
              </div>
            </div>
            <div className="row">
          <div className="col-md-10 offset-md-1">
            <div className="cards-container" style={containerStyles}>
              {[
                'Website Design and Development',
                'E-commerce Solutions',
                'Content Management Systems (CMS)',
                'Web Maintenance and Support',
                'Responsive Design',
                'SEO Integration'
              ].map((title, index) => (
                <div
                  key={index}
                  ref={(el) => {
                    if (el) {
                      tiltRefs.current.push(el);
                      el.vanillaTilt = VanillaTilt.init(el, {
                        max: 25,
                        speed: 400,
                        glare: true,
                        'max-glare': 0.4,
                      });
                    }
                  }}
                  className="card"
                  style={cardStyles}
                >
                  <div className="content" style={contentStyles}>
                    <h3 style={headingStyles}>{title}</h3>
                    <p style={paragraphStyles}>
                       {title === 'Website Design and Development' && 'Crafting responsive, visually appealing websites with a strong focus on user experience.'}
                          {title === 'E-commerce Solutions' && 'Building comprehensive e-commerce platforms to drive online sales and customer engagement.'}
                          {title === 'Content Management Systems (CMS)' && 'Implementing CMS solutions for easy content creation, management, and distribution.'}
                          {title === 'Web Maintenance and Support' && 'Offering ongoing support and maintenance to ensure websites are up-to-date and running smoothly.'}
                          {title === 'Responsive Design' && 'Ensuring websites are effectively optimized for various devices and screen sizes.'}
                          {title === 'SEO Integration' && 'Implementing effective and innovative strategies to improve search engine visibility and rankings.'}
                    </p>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
          <div className="project-cards-container">
            <div className="container">
              <h2 className="project-title">Our Work</h2>
              {projects.map((project, index) => (
                <div className="row" key={index}>
                  {project.reverseLayout ? (
                    <>
                      <div className="col-xs-12 col-md-6 project-description">
                        <div className="project-info">
                          <h3 className="project-subtitle">{project.title}</h3>
                          <p className="project-text">{project.description[0]}</p>
                          <ul className="project-list">
                            {project.description.slice(1).map((line, index) => (
                              <li key={index}>{line}</li>
                            ))}
                          </ul>
                          <button 
                            className="connect-button"
                            onClick={handleConnectClick}
                          >
                            Let’s Connect
                          </button>
                        </div>
                      </div>
                      <div className="col-xs-12 col-md-6 project-card">
                        <TabletCard project={project} />
                      </div>
                    </>
                  ) : (
                    <>
                      <div className="col-xs-12 col-md-6 project-card">
                        <TabletCard project={project} />
                      </div>
                      <div className="col-xs-12 col-md-6 project-description">
                        <div className="project-info">
                          <h3 className="project-subtitle">{project.title}</h3>
                          <p className="project-text">{project.description[0]}</p>
                          <ul className="project-list">
                            {project.description.slice(1).map((line, index) => (
                              <li key={index}>{line}</li>
                            ))}
                          </ul>
                          <button 
                            className="connect-button"
                            onClick={handleConnectClick}
                          >
                            Let’s Connect
                          </button>
                        </div>
                      </div>
                    </>
                  )}
                </div>
              ))}
            </div>
          </div>
        </div>
      )}
      <Footer />
      <ConnectForm isVisible={formVisible} onClose={handleCloseForm} />
    </div>
  );
};

export default WebDevelopment;
