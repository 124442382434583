import React, { useState } from 'react';
import { Card, Col, Row, Container, Button } from 'react-bootstrap';
import backgroundImage from './assets/img/color-sharp2.png';
import ceoImage from './assets/img/ceo.jpg';
import ctoImage from './assets/img/cto.jpg';
import rocketImage from './assets/img/Rocket.png';
import HoverImage from './style/HoverImage';
import { Helmet } from 'react-helmet';
import './style/AboutUs.css';
import CardItem from './CardItems';

const LeadershipMember = ({ imgUrl, name, title, backgroundText = '' }) => {
  const [showFullText, setShowFullText] = useState(false);

  const toggleReadMore = () => {
    setShowFullText(!showFullText);
  };

  return (
    <Row className="d-flex align-items-center mb-4">
      <Col md={6} sm={12} className="d-flex flex-column align-items-center mb-4">
        <HoverImage imgUrl={imgUrl} title={name} description={title} />
      </Col>
      <Col md={6} sm={12} className="text-white">
        <div className="mission-statement mb-4" style={{ textAlign: 'justify', textJustify: 'inter-word', color: '#B8B8B8', fontSize: '18px', letterSpacing: '0.8px', lineHeight: '1.5em' }}>
          {showFullText ? backgroundText : `${backgroundText.substring(0, 300)}...`}
          <Button variant="link" className="p-0 text-white" onClick={toggleReadMore}>
            {showFullText ? 'Read Less' : 'Read More'}
          </Button>
        </div>
      </Col>
    </Row>
  );
};

const MissionStatement = ({ text }) => {
  const [showFullText, setShowFullText] = useState(false);

  const toggleReadMore = () => {
    setShowFullText(!showFullText);
  };

  return (
    <div className="text-white" style={{ paddingLeft: '15px' }}>
      <div className="mission-statement text-center mb-4">
        <h3>Mission Statement</h3>
      </div>
      <div className="mission-statement mb-4" style={{ textAlign: 'justify', textJustify: 'inter-word', color: '#B8B8B8', fontSize: '18px', letterSpacing: '0.8px', lineHeight: '1.5em' }}>
        {showFullText ? text : `${text.substring(0, 300)}...`}
        <Button variant="link" className="p-0 text-white" onClick={toggleReadMore}>
          {showFullText ? 'Read Less' : 'Read More'}
        </Button>
      </div>
    </div>
  );
};

const GoogleMapCard = () => (
  <Card className="google-map-card bg-dark text-white w-100">
    <iframe
      src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3514.905938877612!2d77.37477511507928!3d28.620673582424485!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x390ceff7f2ddf50f%3A0x8e6bd2e7e6fc2e48!2sD-60%2C%20D%20Block%2C%20Sector%2063%2C%20Noida%2C%20Hazratpur%20Wajidpur%2C%20Uttar%20Pradesh%20201301%2C%20India!5e0!3m2!1sen!2sus!4v1591968962439!5m2!1sen!2sin"
      width="100%"
      height="300"
      allowFullScreen=""
      title="Company Location"
    ></iframe>
  </Card>
);

const AboutUs = () => {
  const ceoBackgroundText = `Founded in 2023, YTC TechVentures emerged from a profound passion for technology and a visionary approach to revolutionize the IT services landscape. Our headquarters are strategically located at Mama Chauraha, Raebareli Uttar Pradesh , 229001. 
  Our team comprises a diverse group of experts who bring a wealth of knowledge and experience to the table. Each member is dedicated to delivering top-notch technology solutions that meet the unique needs of our clients. Over the years, we have expanded our services and capabilities, positioning ourselves as a trusted partner for businesses of all sizes. From innovative startups to large enterprises, we cater to a broad range of industries, including finance, healthcare, education, retail, and more. At YTC TechVentures, we believe in the power of technology to transform businesses. Our comprehensive suite of services is designed to enhance operational efficiency, drive growth, and create lasting value. Whether it's developing cutting-edge software, managing complex IT infrastructures, or providing strategic technology consulting, we are committed to excellence in every project we undertake.`;

  const missionStatementText = `To empower businesses with innovative, reliable, and scalable technology solutions that drive growth and efficiency. We are committed to delivering exceptional value and fostering long-term partnerships through excellence in service and continuous innovation. Our mission statement reflects our core values and the principles that guide our operations. We strive to empower our clients by providing technology solutions that are not only advanced and reliable but also tailored to their specific needs. Our focus on innovation ensures that we stay ahead of the curve, continuously developing new methods and solutions to meet the evolving demands of the industry.
  Reliability is at the heart of everything we do. Our clients trust us to deliver high-quality services that enhance their business performance and support their strategic goals. We understand that technology is a critical enabler of success, and we are committed to ensuring that our clients' IT systems are robust, secure, and scalable.
  Scalability is another key aspect of our mission. As businesses grow and evolve, their technology needs change. We design solutions that can seamlessly scale with our clients growth, ensuring that they always have the tools and capabilities they need to succeed.
  At YTC TechVentures, we value long-term partnerships. We believe in building strong, collaborative relationships with our clients, based on mutual trust and respect. Our commitment to excellence in service means that we go above and beyond to meet our clients' expectations and deliver outcomes that drive their success. Through continuous innovation, we aim to stay at the forefront of the technology industry. We invest in research and development, embrace new technologies, and adopt best practices to ensure that our clients benefit from the latest advancements in the field. Our goal is to create technology solutions that not only solve today's challenges but also anticipate and address future needs.`;


  const [showCeoText, setShowCeoText] = useState(false);

  const toggleCeoReadMore = () => {
    setShowCeoText(!showCeoText);
  };
  return (
    <>
      <Helmet>
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <title>About Us - YTC TechVenture</title>
        <meta name="description" content="Learn more about YTC TechVenture, our objectives, core values, and leadership team. We are committed to delivering exceptional value and fostering long-term partnerships through excellence in service and continuous innovation." />
        <meta name="keywords" content="About Us, YTC TechVenture, Objectives, Core Values, Leadership Team" />
        <meta name="author" content="YTC TechVenture" />
        <meta property="og:title" content="About Us - YTC TechVenture" />
        <meta property="og:description" content="Learn more about YTC TechVenture, our objectives, core values, and leadership team. We are committed to delivering exceptional value and fostering long-term partnerships through excellence in service and continuous innovation." />
        <meta property="og:url" content="https://yourwebsite.com/about-us" />
      </Helmet>
      <div id="about-us-section" className="about-us container-fluid p-0" style={{ backgroundImage: `url(${backgroundImage})`, backgroundSize: 'cover' }}>
        <Container>
          <br />
          <h1 className="text-center text-white mt-5 mb-4">About Us</h1>
          <div className="card-scroll-container mb-4">
            <Row>
              {/* Left cards */}
              <Col md={4} className="d-flex justify-content-center">
                <div className="card-container d-flex flex-column justify-content-between">
                  <CardItem title="Objectives" text="Client Satisfaction, Market Leadership, Innovation Hub, Global Reach, Sustainable Growth" />
                  <CardItem title="Market Approach" text="Client-Centric, Industry-Specific Solutions, Innovation-Driven, Collaborative Partnerships, Global Outreach" />
                  <CardItem title="Core Values" text="Innovation, Excellence, Customer-Centricity, Integrity, Growth" />
                </div>
              </Col>

              <Col md={4} className="d-flex justify-content-center align-items-center">
                <img
                  src={rocketImage}
                  alt="Rocket"
                  className="rocket-image img-fluid"
                  style={{ maxWidth: '150%', height: '80%' }}
                />
              </Col>

              {/* Right cards */}
              <Col md={4} className="d-flex justify-content-center">
                <div className="card-container d-flex flex-column justify-content-between">
                  <CardItem title="Key Differentiators" text="Expert Team, Client Focus, Comprehensive Services, Innovative Solutions, Ethical Practices" />
                  <CardItem title="Core Competencies" text="Technological Expertise, Custom Solutions, Agile Methodologies, Quality Assurance, Customer Support" />
                  <CardItem title="Core Values" text="Innovation, Excellence, Customer-Centricity, Integrity, Growth" />
                </div>
              </Col>
            </Row>
          </div>

          {/* Leadership section */}
            <Row className="mb-4 mt-40" style={{ marginTop: '20px' }}>
              <Col md={12} className="text-center mb-3">
                <h3 style={{ color: 'white' }}>Leadership Team</h3> {/* Centered heading */}
              </Col>
            </Row>
            <Row className="background-and-mission-section mb-4">
              <Col md={6} className="d-flex flex-column align-items-center mb-4">
                <div className="text-center">
                  <HoverImage imgUrl={ceoImage} description="CEO" />
                  <h5 className="mt-1">Sanjay Kumar</h5>
                  <p>CEO</p>
                </div>
              </Col>
              <Col md={6}>
                <div className={`mission-statement mb-4 text-margin`} style={{ textAlign: 'justify', textJustify: 'inter-word', color: '#B8B8B8', fontSize: '18px', letterSpacing: '0.8px', lineHeight: '1.5em' }}>
                  {showCeoText ? ceoBackgroundText : `${ceoBackgroundText.substring(0, 300)}...`}
                  <Button variant="link" className="p-0 text-white" onClick={toggleCeoReadMore}>
                    {showCeoText ? 'Read Less' : 'Read More'}
                  </Button>
                </div>
              </Col>
            </Row>




          {/* Mission Statement and CTO Image */}
          <Row className="background-and-mission-section mb-4">
            <Col md={6}>
              <MissionStatement text={missionStatementText} />
            </Col>
            <Col md={6} className="d-flex flex-column align-items-center mb-4">
              <div className="text-center">
                <HoverImage imgUrl={ctoImage} description="CTO" />
                <h5 className="mt-1">Subodh Patidar</h5>
                <p>CTO</p>
              </div>
            </Col>
          </Row>


          {/* Reach Us section */}
          {/* <div className="reach-us-section text-white text-center mb-4">
            <h3 className="mb-4">Reach Us At</h3>
            <Row className="justify-content-center">
              <Col md={6} sm={12} className="d-flex flex-column justify-content-center align-items-center mb-4">  
                <div className="address-block">
                  <h5>Working Address</h5>
                  <p className="mt-3">D-60, D Block, Sector 63, Noida, Hazratpur Wajidpur, Uttar Pradesh 201301, India</p>
                </div>
                <div className="address-block">
                  <h5> Address :</h5>
                  <p className="mt-3">Mama Chauraha,  Raebareli Uttar Pradesh, 229001</p>
                </div>
              </Col>
              <Col md={6} sm={12} className="d-flex justify-content-center mb-4">
                <GoogleMapCard />
              </Col>
            </Row>
          </div> */}
        </Container>
      </div>
    </>
  );
};

export default AboutUs;
