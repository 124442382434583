import React from 'react';
import PropTypes from 'prop-types';
import './style/ProjectCard.css';

const TabletCard = ({ project }) => {
  const cardStyles = {
    position: 'relative',
    width: '100%',
    maxWidth: '600px',
    height: '600px',
    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
    borderRadius: '20px',
    backgroundColor: '#fff',
    overflow: 'hidden',
    margin: '20px auto',
  };

  const iframeStyles = {
    width: '100%',
    height: '100%',
    border: 'none',
  };

  const buttonStyles = {
  position: 'absolute',
  bottom: '10px',
  left: '50%',
  transform: 'translateX(-50%)',
  padding: '10px 20px',
  color: '#fff',
  border: 'none',
  borderRadius: '5px',
  cursor: 'pointer',
  marginTop: '40px', 
  fontSize:'20px',
};

  return (
    <>
    <div className="project-card" style={cardStyles}>
      <iframe src={project.link} title={project.title} style={iframeStyles}></iframe>
    </div>
    <div>
      {/* <button style={buttonStyles} onClick={() => window.open(project.link, '_blank')}>
        Visit
      </button> */}
    </div>
    </>
  );
};

TabletCard.propTypes = {
  project: PropTypes.shape({
    link: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
  }).isRequired,
};

export default TabletCard;
