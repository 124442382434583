import React, { useEffect, useState } from 'react';
import './style/GreetPopUp.css';
import popupVideo from './assets/img/rakhi.mp4';

const GreetPopUp = () => {
  const [showPopup, setShowPopup] = useState(false);
  const [fadeOut, setFadeOut] = useState(false);

  useEffect(() => {
    const today = new Date();
    const isAugust15 = today.getMonth() === 7 && today.getDate() === 19; // August is month 7 (0-based index)

    if (isAugust15) {
      setShowPopup(true);
      // Start the fade-out effect after 2.5 seconds
      const fadeTimer = setTimeout(() => setFadeOut(true), 5000);
      // Auto-close the pop-up after 3 seconds
      const timer = setTimeout(() => setShowPopup(false), 5000);

      return () => {
        clearTimeout(fadeTimer);
        clearTimeout(timer);
      };
    }
  }, []);

  return (
    <>
      {showPopup && (
        <div className={`popup-overlay ${fadeOut ? 'fade-out' : ''}`}>
          <div className={`popup-content ${fadeOut ? 'fade-out' : ''}`}>
            <video
              src={popupVideo}
              alt="Tri-Colour Video"
              className="tricolour-video"
              autoPlay
              loop
              muted
              playsInline
            />
          </div>
        </div>
      )}
    </>
  );
};

export default GreetPopUp;
