import React, { useState, useEffect } from "react";
import { Navbar, Nav, Container } from "react-bootstrap";
import logo from './assets/img/YTC.png';
import { HashLink } from 'react-router-hash-link';
import { Helmet } from "react-helmet";

const NavBar = ({ onConnectClick }) => {
  const [activeLink, setActiveLink] = useState('home');
  const [scrolled, setScrolled] = useState(false);
  const [expanded, setExpanded] = useState(false);

  useEffect(() => {
    const onScroll = () => {
      if (window.scrollY > 50) {
        setScrolled(true);
      } else {
        setScrolled(false);
      }
    };

    window.addEventListener("scroll", onScroll);

    return () => window.removeEventListener("scroll", onScroll);
  }, []);

  const handleNavItemClick = (value) => {
    setActiveLink(value);
    setExpanded(false); 
  };

  return (
    <>
      <Helmet>
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <title>YTC TechVenture</title>
        <meta name="description" content="To empower businesses with innovative, reliable, and scalable technology solutions that drive growth and efficiency. We are committed to delivering exceptional value and fostering long-term partnerships through excellence in service and continuous innovation." />
        <meta name="keywords" content="Web Development, Application Development, SEO Services, Cloud Services, On-Demand Services" />
        <meta name="author" content="YTC TechVenture" />
        <meta property="og:title" content="YTC TechVenture - Innovate. Integrate. Inspire." />
        <meta property="og:description" content="Innovate. Integrate. Inspire." />
        <meta property="og:url" content="https://yourwebsite.com" />
      </Helmet>
      <Navbar
        expand="md"
        className={`${scrolled ? "scrolled" : ""} ${expanded ? "expanded" : ""}`}
        expanded={expanded}
        onToggle={(expanded) => setExpanded(expanded)}
      >
        <Container>
          <Navbar.Brand href="/">
            <img src={logo} alt="Logo" className="navbar-brand" />
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="basic-navbar-nav">
            <span className="navbar-toggler-icon"></span>
          </Navbar.Toggle>
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="ms-auto">
              <Nav.Link as={HashLink} smooth to="#home" className={activeLink === 'home' ? 'active navbar-link' : 'navbar-link'} onClick={() => handleNavItemClick('home')}>Home</Nav.Link>
              <Nav.Link as={HashLink} smooth to="#services" className={activeLink === 'services' ? 'active navbar-link' : 'navbar-link'} onClick={() => handleNavItemClick('services')}>Our Services</Nav.Link>
              <Nav.Link as={HashLink} smooth to="#technology" className={activeLink === 'technology' ? 'active navbar-link' : 'navbar-link'} onClick={() => handleNavItemClick('technology')}>Technology</Nav.Link>
              <Nav.Link as={HashLink} smooth to="#about-us-section" className={activeLink === 'about-us' ? 'active navbar-link' : 'navbar-link'} onClick={() => handleNavItemClick('about-us')}>About Us</Nav.Link>
            </Nav>
            <span className="navbar-text">
              <button className="vvd" onClick={onConnectClick}><span>Let’s Connect</span></button>
            </span>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </>
  );
};

export default NavBar;
