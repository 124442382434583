import React, { useEffect, useRef, useState  } from 'react';
import backgroundImage from './assets/img/color-sharp.png';
import './style/WebDevelopment.css';
import VanillaTilt from 'vanilla-tilt';
import Footer from './Footer';
import ServiceNavbar from './ServicesNavBar';
import ProjectCard from './ProjectCard'; 
import ConnectForm from './ConnectForm'; 


const ApplicationDevelopment = ({ onConnectClick }) => {
  const tiltRefs = useRef([]);
  const [formVisible, setFormVisible] = useState(false); 


  useEffect(() => {
    tiltRefs.current.forEach((card) => {
      VanillaTilt.init(card, {
        max: 25,
        speed: 400,
        glare: true,
        'max-glare': 0.4,
      });

      return () => {
        tiltRefs.current.forEach((card) => {
          if (card && card.vanillaTilt) {
            card.vanillaTilt.destroy();
          }
        });
      };
    });
  }, []);

  const containerStyles = {
    display: 'grid',
    gap: '20px',
    gridTemplateColumns: 'repeat(auto-fit, minmax(300px, 1fr))',
    marginBottom: '40px',
  };

  const cardStyles = {
    position: 'relative',
    width: '100%',
    height: '100%',
    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
    borderRadius: '15px',
    background: 'rgba(128, 128, 128, 0.5)',
    overflow: 'hidden',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    transition: 'transform 0.3s ease',
    cursor: 'pointer',
  };

  const contentStyles = {
    padding: '20px',
    textAlign: 'center',
  };

  const headingStyles = {
    fontSize: '1.8em',
    color: 'aliceblue',
    marginBottom: '15px',
  };

  const paragraphStyles = {
    fontSize: '1em',
    color: '#fff',
    fontWeight: '300',
  };

  const project = {
    title: 'A.S Fashion',
    link: 'https://as-fashion-cf556.web.app/#/',
    description: [
      'Welcome to the future of fashion with AS Fashion, an innovative mobile application proudly developed by YTC TechVentures. Whether you\'re a trendsetter or simply seeking the latest styles, AS Fashion brings your fashion desires right to your fingertips.',
      'Key Features:',
      'AS Fashion is built using Flutter, ensuring a smooth and responsive user experience.',
      'Firebase provides real-time data syncing and secure storage.',
      'Our robust backend, developed using Node.js, guarantees fast and reliable performance.',
      'Partner with YTC TechVentures and experience our expertise in delivering innovative technology solutions. With AS Fashion, we demonstrate our commitment to excellence and our ability to transform ideas into reality.'
    ],
  };

  const handleConnectClick = () => {
    setFormVisible(true);
  };

  const handleCloseForm = () => {
    setFormVisible(false); 
  };
  return (
    <div
      className="application-development"
      style={{
        backgroundImage: `url(${backgroundImage})`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        paddingTop: '40px',
      }}
    >
      <ServiceNavbar />
      <div className="container" style={{ paddingTop: '100px', paddingBottom: '40px' }}>
        <div className="row">
          <div className="col-md-12">
            <div className="heading" style={{ textAlign: 'center', marginBottom: '20px' }}>
              <h1 style={{ marginTop: '0px' }}>Application Development</h1>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-md-10 offset-md-1">
            <div className="cards-container" style={containerStyles}>
              {[
                'Custom Software Development',
                'Mobile App Development',
                'Enterprise Solutions',
                'Cloud Applications',
                'Web Application Development',
                'API Development'
              ].map((title, index) => (
                <div
                  key={index}
                  ref={(el) => {
                    if (el) {
                      tiltRefs.current.push(el);
                      el.vanillaTilt = VanillaTilt.init(el, {
                        max: 25,
                        speed: 400,
                        glare: true,
                        'max-glare': 0.4,
                      });
                    }
                  }}
                  className="card"
                  style={cardStyles}
                >
                  <div className="content" style={contentStyles}>
                    <h3 style={headingStyles}>{title}</h3>
                    <p style={paragraphStyles}>
                      {title === 'Custom Software Development' && 'Creating tailor-made software solutions to meet specific business needs.'}
                      {title === 'Mobile App Development' && 'Designing and developing mobile applications for iOS, Android, and cross-platform solutions.'}
                      {title === 'Enterprise Solutions' && 'Implementing large-scale, robust enterprise applications to streamline business operations.'}
                      {title === 'Cloud Applications' && 'Developing cloud-based applications to enhance accessibility, scalability, and performance.'}
                      {title === 'Web Application Development' && 'Building interactive and responsive web applications using modern technologies.'}
                      {title === 'API Development' && 'Creating and integrating APIs to enable seamless communication between different software applications.'}
                    </p>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
      <div className="project-cards-container">
        <div className="container">
          <h2 className="project-title">Our Work</h2>
          <div className="row">
            <div className="col-xs-12 col-md-6 project-card">
              <ProjectCard project={project} />
            </div>
            <div className="col-xs-12 col-md-6 project-description">
              <div className="project-info">
                <h3 className="project-subtitle">{project.title}</h3>
                <p className="project-text">{project.description[0]}</p>
                <ul className="project-list">
                  {project.description.slice(1).map((line, index) => (
                    <li key={index}>{line}</li>
                  ))}
                </ul>
                <button 
                  className="connect-button"
                  onClick={handleConnectClick}
                >
                  Let’s Connect
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
      <ConnectForm isVisible={formVisible} onClose={handleCloseForm} />

    </div>
  );
};

export default ApplicationDevelopment;
