import React from 'react';
import { Col } from "react-bootstrap";
import './HoverImage.css';

const HoverImage = ({ title, description, imgUrl }) => {
  return (
    <Col xs={12} sm={6} md={4} className="d-flex flex-column align-items-center mb-4">
      <div className="circle-hover">
        <img src={imgUrl} alt={title} className="circle-img" />
        <div className="overlay">
          <div className="text">
            <span>{description}</span>
          </div>
        </div>
      </div>
      <h4 className="mt-2 text-white">{title}</h4> 
    </Col>
  );
}

export default HoverImage;
