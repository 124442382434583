import React from 'react';
import { Navbar, Nav, Container, Button } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import './Style/AppNavbar.css';

const AppNavbar = () => {
  const navigate = useNavigate();

  const handleLogout = () => {
    sessionStorage.clear(); 
    navigate('/admin/login');
  };

  return (
    <Navbar bg="dark" variant="dark" expand="lg" fixed="top" className="custom-navbar">
      <Container>
        <Navbar.Brand href="/">
          <span className="navbar-logo"></span>
          <span className="brand-text">YTC TechVentures</span>
        </Navbar.Brand>
        <Nav className="ml-auto">
          <Button variant="outline-light" onClick={handleLogout}>
            Logout
          </Button>
        </Nav>
      </Container>
    </Navbar>
  );
};

export default AppNavbar;
