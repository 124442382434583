import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

const useAuth = () => {
  const navigate = useNavigate();

  useEffect(() => {
    const email = sessionStorage.getItem('email');
    if (!email) {
      navigate('/admin/login');
    }
  }, [navigate]);

  return sessionStorage.getItem('email') !== null;
};

export default useAuth;
