import React, { useState } from 'react';
import './App.css';
import { Routes, Route, useLocation } from 'react-router-dom';
import NavBar from './components/Navbar';
import Banner from './components/Banner';
import Technology from './components/Technology';
import Services from './components/Services';
import ConnectForm from './components/ConnectForm';
import Footer from './components/Footer';
import AboutUs from './components/AboutUs';
import SocialIcons from './components/SocialMedia'; 
import WebDevelopment from './components/WebDevlopmentServices';
import ApplicationDevelopment from './components/ApplicationDevlopment';
import DigitalMarketing from './components/DigitalMarketing';
import Seo from './components/Seo';
import CloudServices from './components/CloudService';
import AIML from './components/AiMl';
import AdminLogin from './Admin/AdminLogin';
import UserContactDetails from './Admin/UserContactDetails';
import GreetPopUp from './components/GreetPopUp'; 

function App() {
  const [isFormVisible, setFormVisible] = useState(false);
  const location = useLocation();

  const handleConnectClick = () => {
    setFormVisible(true);
  };

  const handleCloseForm = () => {
    setFormVisible(false);
  };

  const shouldShowNavBar = location.pathname === '/';

  return (
    <div className="App">
      <GreetPopUp /> {/* Display the pop-up on website load */}
      {shouldShowNavBar && <NavBar onConnectClick={handleConnectClick} />}
      <Routes>
        <Route path="/" element={
          <>
            <ConnectForm isVisible={isFormVisible} onClose={handleCloseForm} />
            <Banner onConnectClick={handleConnectClick} />
            <Services onConnectClick={handleConnectClick} />
            <Technology />
            <div className="about-us-container"> 
              <AboutUs />
              <Footer className="footer" />
            </div>
          </>
        } />
        <Route path="/services/Web-Development" element={<WebDevelopment />} />
        <Route 
          path="/services/Application-Development" 
          element={<ApplicationDevelopment onConnectClick={handleConnectClick} />} 
        />
        <Route path="/services/Digital-Marketing" element={<DigitalMarketing />} />
        <Route path="/services/SEO-Services" element={<Seo />} />
        <Route path="/services/Cloud-Services" element={<CloudServices />} />
        <Route path="/services/AI-ML-Services" element={<AIML />} />
        <Route path="/admin/login" element={<AdminLogin />} />
        <Route path="/Leadpage" element={<UserContactDetails />} />
      </Routes>
      <SocialIcons />
    </div>
  );
}

export default App;
