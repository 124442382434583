import React, { useState } from 'react';
import { Form, Button, Container, Row, Col } from 'react-bootstrap';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import './Style/AdminLogin.css';
import YTCLogo from '../components/assets/img/YTC.png';

const AdminLogin = () => {
  const [formData, setFormData] = useState({
    email: '',
    password: ''
  });

  const [error, setError] = useState(null);
  const navigate = useNavigate();

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post(
        'https://backend.yadavtechnicalcorp.com/admin/login',
        formData,
        {
          headers: {
            'Content-Type': 'application/json',
            'access-key': 'ASDFGHJ23456$^%$*&dhsjdhshLMKNGBHGsjdgash23bsh'
          },
        }
      );

      if (response.status === 200) {
        setError(null); 
        alert('Login successful!');
        sessionStorage.setItem('email', formData.email); 
        navigate('/Leadpage'); 
      } else {
        setError('Failed to login. Please check your credentials.');
      }
    } catch (error) {
      console.error('Login error:', error);
      setError('Failed to login. Please check your credentials.');
    }
  };

  return (
    <div className="admin-login-container">
      <Container className="mt-5">
        <Row className="justify-content-md-center">
          <Col xs={12} md={6}>
            <div className="login-form-wrapper">
              <div className="logo-container">
                <img src={YTCLogo} alt="YTC Logo" className="company-logo" />
              </div>
              <div className="admin-login-heading">
                <h2 className="text-center mb-4">Admin Login</h2>
              </div>
              <Form onSubmit={handleSubmit}>
                <Form.Group className="mb-3" controlId="email">
                  <Form.Label className="label-black">Email address</Form.Label>
                  <Form.Control
                    type="email"
                    placeholder="Enter email"
                    name="email"
                    value={formData.email}
                    onChange={handleInputChange}
                    required
                  />
                </Form.Group>

                <Form.Group className="mb-3" controlId="password">
                  <Form.Label className="label-black">Password</Form.Label>
                  <Form.Control
                    type="password"
                    placeholder="Password"
                    name="password"
                    value={formData.password}
                    onChange={handleInputChange}
                    required
                  />
                </Form.Group>

                {error && <div className="text-danger">{error}</div>}

                <Button variant="primary" type="submit" className="w-100 mt-3">
                  Login
                </Button>
              </Form>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default AdminLogin;
