import React, { useEffect, useRef } from 'react';
import backgroundImage from './assets/img/color-sharp.png';
// import './style/WebDevelopment.css';
import VanillaTilt from 'vanilla-tilt';
import Footer from './Footer';
import ServiceNavbar from './ServicesNavBar';

const DigitalMarketing = () => {
  const tiltRefs = useRef([]);

  useEffect(() => {
    tiltRefs.current.forEach((card) => {
      VanillaTilt.init(card, {
        max: 25,
        speed: 400,
        glare: true,
        'max-glare': 0.4,
      });

      return () => {
        if (card && card.vanillaTilt) {
          card.vanillaTilt.destroy();
        }
      };
    });
  }, []);

  const containerStyles = {
    display: 'grid',
    gap: '20px',
    gridTemplateColumns: 'repeat(auto-fit, minmax(300px, 1fr))',
    marginBottom: '40px', // Space at the bottom to avoid overlapping with footer
  };

  const cardStyles = {
    position: 'relative',
    width: '100%',
    height: '100%',
    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
    borderRadius: '15px',
    background: 'rgba(128, 128, 128, 0.5)',
    overflow: 'hidden',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    transition: 'transform 0.3s ease',
    cursor: 'pointer',
  };

  const contentStyles = {
    padding: '20px',
    textAlign: 'center',
  };

  const headingStyles = {
    fontSize: '1.8em',
    color: 'aliceblue',
    marginBottom: '15px',
  };

  const paragraphStyles = {
    fontSize: '1em',
    color: '#fff',
    fontWeight: '300',
  };

  return (
    <div
      className="digital-marketing-container"
      style={{
        backgroundImage: `url(${backgroundImage})`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        paddingTop: '60px',
      }}
    >
      <ServiceNavbar />
      <div className="container" style={{ paddingTop: '100px', paddingBottom: '40px' }}> {/* Padding added to prevent overlap with navbar */}
        <div className="row">
          <div className="col-md-12">
            <div className="heading" style={{ textAlign: 'center', marginTop: '60px' }}>
              <h1 style={{ color: 'white' }}>Digital Marketing</h1>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-md-10 offset-md-1">
            <div className="cards-container" style={containerStyles}>
              {[
                'Search Engine Optimization (SEO)',
                'Pay-Per-Click (PPC) Advertising',
                'Social Media Marketing',
                'Email Marketing',
                'Content Marketing',
                'Influencer Marketing'
              ].map((title, index) => (
                <div
                  key={index}
                  ref={(el) => {
                    if (el) {
                      tiltRefs.current.push(el);
                    }
                  }}
                  className="card"
                  style={cardStyles}
                >
                  <div className="content" style={contentStyles}>
                    <h3 style={headingStyles}>{title}</h3>
                    <p style={paragraphStyles}>
                      {title === 'Search Engine Optimization (SEO)' && 'Improving website visibility on search engines to attract organic traffic.'}
                      {title === 'Pay-Per-Click (PPC) Advertising' && 'Managing PPC campaigns to drive targeted traffic and maximize ROI.'}
                      {title === 'Social Media Marketing' && 'Developing and executing social media strategies to build brand presence and engage with audiences.'}
                      {title === 'Email Marketing' && 'Creating and managing email campaigns to nurture leads and retain customers.'}
                      {title === 'Content Marketing' && 'Creating and distributing valuable, relevant, and consistent content to attract and retain a clearly defined audience.'}
                      {title === 'Influencer Marketing' && 'Collaborating with influential individuals to promote products or services and reach a wider audience.'}
                    </p>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default DigitalMarketing;
