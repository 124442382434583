import { Col } from "react-bootstrap";
import { Link } from "react-router-dom"; 

const ServicesCard = ({ title, description, imgUrl }) => {
  // Replace spaces with hyphens in the title for the URL
  const formattedTitle = title.replace(/ /g, '-');

  return (
    <Col xs={12} sm={6} md={4}>
      <div className="proj-imgbx">
        <img src={imgUrl} alt={title} className="proj-img" />
        <div className="proj-txtx">
          <h4>{title}</h4>
          <span>{description}</span>
          <Link to={`/services/${formattedTitle}`} className="btn btn-outline-info">
            View Details
          </Link>
        </div>
      </div>
    </Col>
  );
}

export default ServicesCard;
