import { useState, useEffect, useCallback } from "react";
import { Container, Row, Col } from "react-bootstrap";
import headerImg from "../components/assets/img/header-img.svg";
import { ArrowRightCircle } from 'react-bootstrap-icons';
import TrackVisibility from 'react-on-screen';
import { Helmet } from "react-helmet";

const Banner = ({ onConnectClick }) => {
  const [loopNum, setLoopNum] = useState(0);
  const [isDeleting, setIsDeleting] = useState(false);
  const [text, setText] = useState('');
  const [delta, setDelta] = useState(300 - Math.random() * 100);
  const toRotate = ["Web Development", "Artificial Intelligence", "UI/UX Designer", "Machine Learning", "AWS Solutions", "CMS & E-Commerce Development", "Digital Marketing", "On-Demand Services"];
  const period = 1000;

  const tick = useCallback(() => {
    let i = loopNum % toRotate.length;
    let fullText = toRotate[i];
    let updatedText = isDeleting ? fullText.substring(0, text.length - 1) : fullText.substring(0, text.length + 1);

    setText(updatedText);

    if (isDeleting) {
      setDelta(prevDelta => prevDelta / 2);
    }

    if (!isDeleting && updatedText === fullText) {
      setIsDeleting(true);
      setDelta(period);
    } else if (isDeleting && updatedText === '') {
      setIsDeleting(false);
      setLoopNum(loopNum + 1);
      setDelta(300);
    }
  }, [isDeleting, loopNum, text.length, toRotate, period]);

  useEffect(() => {
    let ticker = setInterval(() => {
      tick();
    }, delta);

    return () => { clearInterval(ticker) };
  }, [tick, delta]);

  return (
    <>
      <Helmet>
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <title>YTC TechVenture - Innovate. Integrate. Inspire.</title>
        <meta name="description" content="To empower businesses with innovative, reliable, and scalable technology solutions that drive growth and efficiency. We are committed to delivering exceptional value and fostering long-term partnerships through excellence in service and continuous innovation." />
        <meta name="keywords" content="Web Development, Artificial Intelligence, UI/UX Designer, Machine Learning, AWS Solutions, CMS Development, E-Commerce Development, Digital Marketing, On-Demand Services" />
        <meta name="author" content="YTC TechVenture" />
        <meta property="og:title" content="YTC TechVenture - Innovate. Integrate. Inspire." />
        <meta property="og:description" content="To empower businesses with innovative, reliable, and scalable technology solutions that drive growth and efficiency. We are committed to delivering exceptional value and fostering long-term partnerships through excellence in service and continuous innovation." />
        <meta property="og:url" content="https://yourwebsite.com" />
      </Helmet>
      <section className="banner" id="home">
        <Container>
          <Row className="align-items-center">
            <Col xs={12} md={6} xl={7}>
              <TrackVisibility>
                {({ isVisible }) =>
                  <div className={isVisible ? "animate__animated animate__fadeIn" : ""}>
                    <span className="tagline">Welcome to YTC TechVenture</span>
                    <h1>Innovate. Integrate. Inspire.</h1>
                    <h1>
                      <span className="txt-rotate" dataPeriod="1000" data-rotate='["Web Development", "Artificial Intelligence", "UI/UX Designer", "Machine Learning", "AWS Solutions", "CMS & E-Commerce Development", "Digital Marketing", "On-Demand Services"]'>
                        <span className="wrap">{text}</span>
                      </span>
                    </h1>
                    <p>To empower businesses with innovative, reliable, and scalable technology solutions that drive growth and efficiency. We are committed to delivering exceptional value and fostering long-term partnerships through excellence in service and continuous innovation.</p>
                    <button onClick={onConnectClick}>Let’s Connect <ArrowRightCircle size={25} /></button>
                  </div>}
              </TrackVisibility>
            </Col>
            <Col xs={12} md={6} xl={5}>
              <TrackVisibility>
                {({ isVisible }) =>
                  <div className={isVisible ? "animate__animated animate__zoomIn" : ""}>
                    <img src={headerImg} alt="Header Img" className="img-fluid" />
                  </div>}
              </TrackVisibility>
            </Col>
          </Row>
        </Container>
      </section>
    </>
  );
}

export default Banner;
