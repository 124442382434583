import React, { useEffect, useRef } from 'react';
import backgroundImage from './assets/img/color-sharp.png';
import VanillaTilt from 'vanilla-tilt';
import Footer from './Footer';
import ServiceNavbar from './ServicesNavBar';

const CloudServices = () => {
  const tiltRefs = useRef([]);

  useEffect(() => {
    tiltRefs.current.forEach((card) => {
      VanillaTilt.init(card, {
        max: 25,
        speed: 400,
        glare: true,
        'max-glare': 0.4,
      });

      return () => {
        if (card && card.vanillaTilt) {
          card.vanillaTilt.destroy();
        }
      };
    });
  }, []);

  return (
    <div className="cloud-services-container" style={{ backgroundImage: `url(${backgroundImage})`, backgroundSize: 'cover', backgroundPosition: 'center', paddingTop: '40px' }}>
      <ServiceNavbar />
      <div className="container" style={{ marginTop: '40px', marginBottom: '40px' }}>
        <div className="row">
          <div className="col-md-12">
            <div className="heading" style={{ textAlign: 'center', marginBottom: '40px' }}>
              <h1 style={{ marginTop: '40px' }}>Cloud Services</h1>
            </div>
          </div>
        </div>
        <div className="row">
          {[
            'Cloud Consulting',
            'Cloud Migration',
            'Cloud Management',
            'Cloud Security',
            'Cloud Backup & Recovery',
            'DevOps Services'
          ].map((title, index) => (
            <div key={index} className="col-md-4" style={{ marginBottom: '20px', display: 'flex', justifyContent: 'center' }}>
              <div
                ref={(el) => {
                  if (el) {
                    tiltRefs.current.push(el);
                    el.vanillaTilt = VanillaTilt.init(el, {
                      max: 25,
                      speed: 400,
                      glare: true,
                      'max-glare': 0.4,
                    });
                  }
                }}
                className="card"
                style={{
                  width: '100%',
                  height: '100%',
                  boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
                  borderRadius: '15px',
                  background: 'rgba(128, 128, 128, 0.5)', // Adjust the background color and opacity as needed
                  overflow: 'hidden',
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'space-between',
                  padding: '20px', // Padding added here
                  cursor: 'pointer'
                }}
              >
                <div className="content" style={{ textAlign: 'center' }}>
                  <h3 style={{ fontSize: '1.8em', color: 'aliceblue', marginBottom: '15px' }}>{title}</h3>
                  <p style={{ fontSize: '1em', color: '#fff', fontWeight: '300' }}>
                    {title === 'Cloud Consulting' && 'Providing expert advice on cloud strategy, architecture, and implementation.'}
                    {title === 'Cloud Migration' && 'Helping businesses move their applications and data to the cloud seamlessly.'}
                    {title === 'Cloud Management' && 'Offering ongoing management and optimization of cloud environments.'}
                    {title === 'Cloud Security' && 'Implementing robust security measures to protect cloud assets and data.'}
                    {title === 'Cloud Backup & Recovery' && 'Ensuring data integrity and availability with comprehensive backup and recovery solutions.'}
                    {title === 'DevOps Services' && 'Enhancing development and operations through continuous integration and delivery practices.'}
                  </p>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default CloudServices;
