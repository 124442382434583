import React from 'react';
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import colorSharp from './assets/img/color-sharp.png';
import djangoLogo from './assets/img/django.svg';
import reactLogo from './assets/img/React-icon.png';
import nodeLogo from './assets/img/Nodejs.svg';
import pythonLogo from './assets/img/Python_logo.png';
import flutterLogo from './assets/img/flutter_logo.svg';
import javaScriptLogo from './assets/img/JavaScript-logo.png';
import angularLogo from './assets/img/Angular.png';
import mongodbLogo from './assets/img/Mongodb-PNG.png';
import MySql from './assets/img/mysql.svg';
import './style/Technology.css';
import { Helmet } from 'react-helmet';

const Technology = () => {
  const responsive = {
    superLargeDesktop: { breakpoint: { max: 4000, min: 3000 }, items: 5 },
    desktop: { breakpoint: { max: 3000, min: 1024 }, items: 3 },
    tablet: { breakpoint: { max: 1024, min: 464 }, items: 2 },
    mobile: { breakpoint: { max: 464, min: 0 }, items: 1 }
  };

  return (
    <>
      <Helmet>
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <title>Trending Technologies - YTC TechVenture</title>
        <meta name="description" content="Discover the trending technologies that we use to build innovative solutions. From JavaScript and React to Python and Django, we leverage the latest tech stack to drive growth and efficiency." />
        <meta name="keywords" content="Web Development, Artificial Intelligence, UI/UX Designer, Machine Learning, AWS Solutions, CMS Development, E-Commerce Development, Digital Marketing, On-Demand Services, JavaScript, React, Node.js, Python, Django, Flutter, AngularJS, MongoDB, MySQL" />
        <meta name="author" content="YTC TechVenture" />
        <meta property="og:title" content="Trending Technologies - YTC TechVenture" />
        <meta property="og:description" content="Discover the trending technologies that we use to build innovative solutions. From JavaScript and React to Python and Django, we leverage the latest tech stack to drive growth and efficiency." />
        <meta property="og:url" content="https://yourwebsite.com/technology" />
      </Helmet>
      <section className="skill" id="technology">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="skill-bx wow zoomIn" style={{marginTop:'20px'}}>
                <h2>WE BUILD USING TRENDING TECHNOLOGY</h2>
                <hr className="separator" /> 
                <Carousel
                  responsive={responsive}
                  infinite={true}
                  autoPlay={true}
                  autoPlaySpeed={1500}
                  arrows={false}
                  className="owl-carousel owl-theme skill-slider"
                >
                  <div className="item">
                    <img src={javaScriptLogo} alt="JavaScript" />
                  </div>
                  <div className="item">
                    <img src={reactLogo} alt="React" />
                  </div>
                  <div className="item">
                    <img src={nodeLogo} alt="Node.js" />
                  </div>
                  <div className="item">
                    <img src={pythonLogo} alt="Python" />
                  </div>
                  <div className="item">
                    <img src={flutterLogo} alt="Flutter" />
                  </div>
                  <div className="item">
                    <img src={djangoLogo} alt="Django" />
                  </div>
                  <div className="item">
                    <img src={angularLogo} alt="AngularJS" />
                  </div>
                  <div className="item">
                    <img src={mongodbLogo} alt="Mongodb" />
                  </div>
                  <div className="item">
                    <img src={MySql} alt="MySql" />
                  </div>
                </Carousel>
              </div>
            </div>
          </div>
        </div>
        <img className="background-image-left" src={colorSharp} alt="Background" />
      </section>
    </>
  );
}

export default Technology;
