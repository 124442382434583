import React, { useEffect, useRef } from 'react';
import backgroundImage from './assets/img/color-sharp.png';
// import './style/WebDevelopment.css';
import VanillaTilt from 'vanilla-tilt';
import Footer from './Footer';
import ServiceNavbar from './ServicesNavBar';

const Seo = () => {
  const tiltRefs = useRef([]);

  useEffect(() => {
    tiltRefs.current.forEach((card) => {
      VanillaTilt.init(card, {
        max: 25,
        speed: 400,
        glare: true,
        'max-glare': 0.4,
      });

      return () => {
        if (card && card.vanillaTilt) {
          card.vanillaTilt.destroy();
        }
      };
    });
  }, []);

  const containerStyles = {
    display: 'grid',
    gap: '20px',
    gridTemplateColumns: 'repeat(auto-fit, minmax(300px, 1fr))',
    marginBottom: '40px', // Space at the bottom to avoid overlapping with footer
  };

  const cardStyles = {
    position: 'relative',
    width: '100%',
    height: '100%',
    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
    borderRadius: '15px',
    background: 'rgba(128, 128, 128, 0.5)',
    overflow: 'hidden',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    transition: 'transform 0.3s ease',
    cursor: 'pointer',
  };

  const contentStyles = {
    padding: '20px',
    textAlign: 'center',
  };

  const headingStyles = {
    fontSize: '1.8em',
    color: 'aliceblue',
    marginBottom: '15px',
  };

  const paragraphStyles = {
    fontSize: '1em',
    color: '#fff',
    fontWeight: '300',
  };

  return (
    <div
      className="seo-container"
      style={{
        backgroundImage: `url(${backgroundImage})`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        paddingTop: '60px',
      }}
    >
      <ServiceNavbar />
      <div className="container" style={{ paddingTop: '100px', paddingBottom: '40px' }}> {/* Padding added to prevent overlap with navbar */}
        <div className="row">
          <div className="col-md-12">
            <div className="heading" style={{ textAlign: 'center', marginTop: '60px' }}>
              <h1 style={{ color: 'white' }}>Search Engine Optimization (SEO)</h1>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-md-8 offset-md-2">
            <div className="cards-container" style={containerStyles}>
              {[
                'On-Page SEO',
                'Off-Page SEO',
                'Local SEO',
                'SEO Audits and Reporting',
              ].map((title, index) => (
                <div
                  key={index}
                  ref={(el) => {
                    if (el) {
                      tiltRefs.current.push(el);
                    }
                  }}
                  className="card"
                  style={cardStyles}
                >
                  <div className="content" style={contentStyles}>
                    <h3 style={headingStyles}>{title}</h3>
                    <p style={paragraphStyles}>
                      {title === 'On-Page SEO' && 'Optimizing website content, structure, and HTML elements for better search engine rankings.'}
                      {title === 'Off-Page SEO' && 'Building high-quality backlinks and improving online reputation through various off-site strategies.'}
                      {title === 'Local SEO' && 'Enhancing local search visibility for businesses to attract customers in specific geographic areas.'}
                      {title === 'SEO Audits and Reporting' && 'Conducting comprehensive SEO audits and providing detailed performance reports.'}
                    </p>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Seo;
